body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

canvas {
  display: flex;
  z-index: -1;
}

body {
  /* padding: 0;
  margin: 0;
  background: linear-gradient(270deg, #62b747, #368a79, #abcc4f);
  background-size: 600% 600%;
  -webkit-animation: AnimationName 45s ease infinite;
  -moz-animation: AnimationName 45s ease infinite;
  animation: AnimationName 45s ease infinite;  */
}


@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@keyframes AnimationName { 
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

@media (max-width: 576px) {
  canvas {
    display: none
  }
}

